<template>
  <v-sheet class="ma-10">
    <v-card elevation="0">
      <v-tabs
        v-model="tab"
        vertical
        align-with-title
        color="indigo"
        @change="tabChange"
      >
        <v-tab>
          <v-icon left>
            mdi-shopping
          </v-icon>
          {{ $t("app.shop") }}
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-book-open-variant
          </v-icon>
          {{ $t("app.my_book") }}
        </v-tab>
        <v-tab>
          <v-icon left>
            mdi-bookmark-outline
          </v-icon>
          Wishlist
        </v-tab>

        <v-tabs-items v-model="tab" touchless>
          <v-tab-item>
            <MainContent showTab />
          </v-tab-item>
          <v-tab-item>
            <Detail v-if="showDetail" />
            <v-card v-else class="overflow-hidden">
              <v-sheet class="overflow-y-auto" max-height="600">
                <v-container style="height: 1000px;">
                  <v-row>
                    <v-col
                      v-for="item in listMyEbook"
                      :key="item.id"
                      cols="12"
                      sm="4"
                      lg="2"
                    >
                      <Card
                        :title="item.title"
                        :img="item.thumbnail"
                        :author="item.author"
                        :reads="item.reads"
                        :likes="item.likes"
                        :userLike="item.user_like"
                        :userRead="isUserRead(item)"
                        :idBook="item.id"
                        :wishlist="item.wishlist"
                        :userWishlist="item.user_wishlist"
                        :genre="item.genre"
                        @getData="getEbooksMe()"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <Detail v-if="showDetail" />
            <v-card v-else class="overflow-hidden">
              <v-sheet class="overflow-y-auto" max-height="600">
                <v-container style="height: 1000px;">
                  <v-row>
                    <v-col
                      v-for="item in listMyWishlist"
                      :key="item.id"
                      cols="12"
                      sm="4"
                      lg="2"
                    >
                      <Card
                        :title="item.title"
                        :img="item.thumbnail"
                        :author="item.author"
                        :reads="item.reads"
                        :likes="item.likes"
                        :userLike="item.user_like"
                        :userRead="isUserRead(item)"
                        :idBook="item.id"
                        :wishlist="item.wishlist"
                        :userWishlist="item.user_wishlist"
                        :genre="item.genre"
                        @getData="getMyWishlist()"
                      />
                    </v-col>
                  </v-row>
                </v-container>
              </v-sheet>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </v-card>
  </v-sheet>
</template>

<script>
import { Card, Detail } from "./components/InspirationEbook";
import { ebookMe, myWishlist } from "@/api/homepage/ebook";
import { MainContent } from "./components/InspirationEbook";

import { mapGetters } from "vuex";

const limitItem = 12;

export default {
  components: {
    Card,
    Detail,
    MainContent
  },
  created() {
    const { query, name } = this.$route;
    this.isEbook = name === "Ebook";
    this.watchTab(query.tab);
  },
  watch: {
    "$route.query.tab"(n) {
      this.watchTab(n);
    }
  },
  computed: {
    ...mapGetters(["g_language"]),
    showDetail() {
      const { query } = this.$route;
      return query.detail !== null && query.detail !== undefined;
    }
  },
  data() {
    return {
      isEbook: false,
      tab: this.$route.query.tab | 0,
      loadingEbookMe: false,
      loadingListWhislist: false,
      listMyEbook: [],
      listMyWishlist: [],
      payloadMyEbook: {
        page: 1,
        limit: limitItem,
        sort: "id",
        order: "ASC"
      },
      payloadMyWishlist: {
        page: 1,
        limit: limitItem,
        sort: "id",
        order: "ASC"
      }
    };
  },
  methods: {
    isUserRead(item) {
      return this.isEbook ? item.user_read : item.user_view;
    },
    watchTab(value) {
      switch (parseInt(value)) {
        case 1:
          this.getEbooksMe();
          break;
        case 2:
          this.getMyWishlist();
          break;
      }
    },
    tabChange(tab) {
      this.$router.push({ name: this.$route.name, query: { tab: tab } });
    },
    async getEbooksMe() {
      this.$Progress.start();
      this.loadingEbookMe = true;
      try {
        const { data } = await ebookMe(this.payloadMyEbook);
        if (data.code) this.listMyEbook = data.data.data;
      } catch (error) {
        console.error("getEbookMe()\n", error);
      } finally {
        this.$Progress.finish();
        this.loadingEbookMe = false;
      }
    },
    async getMyWishlist() {
      this.$Progress.start();
      this.loadingListWhislist = true;
      try {
        const { data } = await myWishlist(this.payloadMyWishlist);
        if (data.code) this.listMyWishlist = data.data.data;
      } catch (error) {
        console.error("getMyWishlist()\n", error);
      } finally {
        this.$Progress.finish();
        this.loadingListWhislist = false;
      }
    }
  }
};
</script>
